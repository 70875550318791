import { createRouter, createWebHistory } from 'vue-router'
import PaginaInicial from '../views/PaginaInicial.vue'
import Exames from '../views/Exames.vue'
import Contato from '../views/Contato.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'inicio',
    component: PaginaInicial,
    meta: { title: 'Dr. André | Oftalmologista PVH' }
  },
  {
    path: '/exames',
    name: 'exames',
    component: Exames,
    meta: { title: 'Exames | Dr. André' }
  },
  {
    path: '/contato',
    name: 'contato',
    component: Contato,
    meta: { title: 'Contato | Dr. André' }
  },
  { 
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: NotFound,
    meta: { title: 'Página Não Encontrada | Dr. André'}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
})

export default router
