export async function checkWebpSupport() {
    /**
     * Usada para checar o suporte a imagens WebP para definir qual imagem deve ser carregada.
     * @returns {Promise} - Retorna booleana "true/false" a respeito do suporte do navegador para WebP.
     */
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.onload = function() { resolve(true); };
        img.onerror = function() { reject(false); };
        img.src = 'http://www.gstatic.com/webp/gallery/1.webp';
    })
};

export async function htmlInterpreter(url, selector) {
    /**
     * Função para interpretar um documento HTML usando o DOMParser.
     * @param {string} html - O caminho do arquivo HTML a ser carregado.
     * @param {string} selector - O seletor CSS usado para encontrar o elemento HTML desejado.
     * @returns {Promise} - Uma promessa que será resolvida com o elemento HTML encontrado ou rejeitada com um erro.
     */

    return new Promise(async (resolve, reject) => {
        try {
            const htmlDoc = await fetch(url)
                .then(res => res.text())
                .catch(error => {throw new Error(error)});

            const parser = new DOMParser();
            const htmlContent = parser.parseFromString(htmlDoc, 'text/html');

            if(htmlContent.querySelector(selector)) {
                resolve(htmlContent.querySelector(selector));
            } else {
                throw new Error(`The ${selector} couldn't be found in the HTML of ${url}.`);
            }
        } catch (error) {
            reject(error);
        }
    })
};

export async function getReviews(url) {
    let apiEndPoint;
    if(window.location.host.includes('localhost')){
        apiEndPoint = 'http://127.0.0.1:5001/dr-andre-pvh/us-central1/buscarURL?url=';
    } else {
        apiEndPoint = 'https://us-central1-dr-andre-pvh.cloudfunctions.net/buscarURL?url=';
    }

    const reviewDomain = new URL(url).hostname;
    let htmlContent;
    let reviewList = [];

    return new Promise(async (resolve, reject) => {
        switch (reviewDomain) {
            case 'www.doctoralia.com.br':
                await htmlInterpreter(apiEndPoint + url, '#profile-reviews')
                    .then(res => htmlContent = res);
    
                //Reviewer name
                htmlContent.querySelectorAll('[data-id="doctor-opinions-list"] h4 > span').forEach((el, index) => {
                    let name = el.textContent.trim().split(' ', 2);
    
                    if(!(name[1].includes('.'))){
                        name[1] = name[1].slice(0,1) + '.';
                        name = name.join(' ');
    
                        reviewList.push({name: name});
                    } else {
                        reviewList.push({name: name.join(' ')});
                    };
                });
    
                //Review date
                htmlContent.querySelectorAll('[data-id="doctor-opinions-list"] time').forEach((el, index) => {
                    reviewList[index].date = el.textContent.trim();
                });
    
                //Review
                htmlContent.querySelectorAll('[data-test-id="opinion-comment"]').forEach((el, index) => {
                    reviewList[index].reviewComment = el.textContent.trim();
                });
    
                //Source
                htmlContent.querySelectorAll('[data-id="doctor-opinions-list"] h4 > span').forEach((el, index) => {
                    reviewList[index].source = 'Doctoralia';
                    reviewList[index].sourceURL = url;
                });
                break;
        
            default:
                reject(`O domínio ${reviewDomain} não está registrado para coleta de avaliações.`)
                break;
        };

        resolve(reviewList);
    })
};