<template>
  <article class="examCard examCardSkeleton">
    <div></div>
    <div>
        <h3>Carregando...</h3>
        <p>Carregando as informações sobre os exames oferecidos, isso pode demorar um pouco, por favor, aguarde...</p>
    </div>
  </article>
</template>

<script>
export default {

}
</script>

<style>
.examCard.examCardSkeleton {
    user-select: none;
}

.examCard.examCardSkeleton div:nth-of-type(1) {
    height: 96px;
    width: 96px;
    margin-bottom: 20px;
    border-radius: 15px;
    background-color: lightgrey !important;
    animation: infinite ease-in-out 1500ms;
    animation-name: ExamCardSkeletonLoading;
}

.examCard.examCardSkeleton * {
    font-family: var(--txt-skeleton) !important;
    color: lightgray !important;
    animation: infinite ease-in-out 1500ms;
    animation-name: ExamCardSkeletonLoading;
}

    @keyframes ExamCardSkeletonLoading {
        0% {filter: opacity(1);}
        50% {filter: opacity(0.5);}
        100% {filter: opacity(1);}
    }
</style>