<template>
  <section :class="{
    'cabecalho-principal': Type === 'principal',
    'cabecalho-secundario': Type === 'secundario'
    }">
    <h2>{{ Title }}</h2>
    <p>{{ Descr }}</p>
    <slot></slot>
  </section>
</template>

<script>

export default {
    props: ['Type', 'Title', 'Descr'],
}
</script>

<style>
  section.cabecalho-principal h2 {margin-bottom: 30px;}
  section.cabecalho-secundario h2 {color: var(--txt-cor_p);}

  @media screen and (min-width: 425px){
    /*Visão de tablet*/
    section.cabecalho-principal h2 {margin-bottom: 0px;}

    section.cabecalho-principal, section.cabecalho-secundario {
      text-align: center;
    }
  }
</style>