<template>
  <article class="reviewCard">
    <div>
      <h3>{{ Name }}</h3>
      <p>{{ ReviewDate }}</p>
    </div>
    <div>
      <p>{{ ReviewComment }}</p>
    </div>
    <div>
      <a :href="ReviewSourceURL" hreflang="pt-BR" rel="nofollow noreferrer external" target="_blank" title="Visitar fonte da avaliação">
        <img :src="`${require('@/assets/recursos-extras/logo_doctoralia.png')}`" width="100" height="25" loading="lazy" alt="Logo do site da fonte da avaliação">
      </a>
    </div>
  </article>
</template>

<script>
export default {
  props: ['Name', 'ReviewDate', 'ReviewComment', 'ReviewSourceURL', 'ReviewSourceName'],
}
</script>

<style>
.reviewCard {
  height: fit-content;
  width: 250px;
  background-color: white;
  padding: 30px 30px 15px 30px;
  border-radius: var(--borda-raio_grande);
  box-shadow: var(--sombra-leve);
  text-align: left;
  transition: 500ms ease-in-out;
}

.reviewCard div:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.reviewCard div:nth-of-type(1) p {margin: 0px;}

.reviewCard div:nth-of-type(3) {
  display: grid;
  justify-items: end;
}

  @media screen and (min-width: 768px) {
    .reviewCard {
      width: 500px;
    }

    .reviewCard div:nth-of-type(2) {
      max-height: 100px;
      overflow-y: auto;
    }
  }
</style>