<template>
<main>
  <section>
    <PageSection
    :Type="`principal`"
    :Title="`Diversos exames a preços populares`"
    :Descr="`O Dr. André oferece diversos exames oftalmológicos em Porto Velho, para todas as idades, conheça-os aqui.`"/>
  </section>

  <section>
    <div v-if="examsJSON.length > 0">
      <ExamCard v-for="exam in examsJSON" :key="exam.id" :ExamInfo="exam"/>
    </div>
    <div v-if="!examsJSON.length > 0">
      <ExamCardSkeleton v-for="index in 5" :key="index"/>
    </div>
  </section>
</main>
</template>

<script>
import PageSection from '@/components/PageSection.vue'
import ExamCard from '@/components/ExamCard.vue'
import ExamCardSkeleton from '@/components/ExamCardSkeleton.vue'
import { globalScheduleStatus } from '@/stores/schedule.js'

export default {
  data() {
    return {
      globalScheduleStatus,
      examsJSON: globalScheduleStatus.examsJSON
    }
  },
  components: { PageSection, ExamCard, ExamCardSkeleton },
  watch: {
    'globalScheduleStatus.examsJSON'(newValue, oldValue) {
      this.examsJSON = newValue;
    }
  }
}
</script>

<style>
section > div:has(.examCard) {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
  justify-content: center;
  align-content: flex-start;
}
</style>