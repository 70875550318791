<template>
  <article class="clinicCard" ref="clinicCard">
    <section>
      <Button @click="prevCard" aria-label="Exibir clínica anterior" :BtnIcon="'ph-caret-left'" class="examCardActionBtn"/>
      <div>
        <h3>{{ Name }}</h3>
        <p>{{ Address }}</p>
      </div>
      <Button @click="nextCard" aria-label="Exibir próxima clínica" :BtnIcon="'ph-caret-right'" class="examCardActionBtn"/>
      <details>
        <summary>Mais informações</summary>
        <p v-if="Info">{{ Info }}</p>
        <a rel="external nofollow" target="_blank" hreflang="pt-BR"
        :href="`https://api.whatsapp.com/send/?phone=${PhoneNumber}&text=${encodeURIComponent('Olá, eu gostaria de agendar uma consulta com o Dr. André oftalmologista')}`" @click="emitGTMEvent(ClinicID)">
          <Button :BtnType="'primario'" :BtnTxt="'Agendar consulta'" :BtnIcon="'ph-arrow-square-out'" />
        </a>
      </details>
    </section>
    <div></div>
  </article>
</template>

<script>
import Button from '@/components/Button.vue';
import { useGtm } from '@gtm-support/vue-gtm';
import { getClinicImage } from '@/composables/db';

export default {
  props: ['Name', 'Address', 'Info', 'PhoneNumber', 'Photo', 'ClinicID'],
  components: {Button},
  mounted() {
    this.getImage(this.Photo);
  },
  methods: {
    async getImage(imageName) {
      try {
        const url = await getClinicImage(imageName);

        const clinicCardElement = this.$refs.clinicCard;

        clinicCardElement.style.backgroundImage = `url(${url})`;

        return url;
      } catch (error) {
        console.error(error);
      }
    },
    prevCard() {
      this.$emit('prevCard');
    },
    nextCard() {
      this.$emit('nextCard');
    },
    emitGTMEvent(consultorioID) {
      useGtm().trackEvent({
        event: 'agendar-consulta',
        consultorioId: consultorioID
      })
    }
  }
}
</script>

<style>
.clinicCard {
  display: grid;
  position: relative;
  align-items: end;
  justify-items: center;
  height: fit-content;
  width: 100%;
  margin: auto;
  aspect-ratio: 9/16;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  box-shadow: var(--sombra-media);
  border-radius: 30px;
}

.clinicCard:nth-of-type(n+2){display:none;}

.clinicCard section {
  display: grid;
  grid-template-columns: 45px auto 45px; 
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  width: 100%;
  padding: 15px;
  margin: 0px auto;
  text-align: center;
  z-index: 1;
}

.clinicCard section * {color: white;}

.clinicCard .examCardActionBtn {
  height: 45px;
  width: 45px;
  background: transparent;
  box-shadow: none;
  color: white;
}

.clinicCard .examCardActionBtn i {font-size: 45px;}
.clinicCard section > div p {margin: 0px auto 10px auto;}

.clinicCard > div {
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0px;
  background-image: linear-gradient(360deg, #1A1A1A, transparent 50%);
}

.clinicCard details {grid-column: 1/span 3;}

.clinicCard details a {
  height: fit-content;
  width: 100%;
  margin-top: 20px;
}

.clinicCard details a > button {width: 100%;}

@media screen and (min-width: 768px) {
  /*visão de computador*/
  .clinicCard {
    aspect-ratio: 3/4;
  }
}
</style>