import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator, collection, getDocs, query, where } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, connectStorageEmulator } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyASj5p6uV4CjsaAvq2Nb2OaMiqq8cRTfJ8",
    authDomain: "dr-andre-pvh.firebaseapp.com",
    projectId: "dr-andre-pvh",
    storageBucket: "dr-andre-pvh.appspot.com",
    messagingSenderId: "996943105135",
    appId: "1:996943105135:web:615ead3efa21544302919a",
    measurementId: "G-NXVMHNC09L"
};

const app = initializeApp(firebaseConfig);
const bd = getFirestore(app);
const storage = getStorage();

if(window.location.host === 'localhost:5500'){
    connectFirestoreEmulator(bd, 'localhost', 8080);
    connectStorageEmulator(storage, 'localhost', 9199);
} else {}

const examsCollection = collection(bd, 'exames');
const clinicsCollection = collection(bd, 'consultorios');

export function getExams() {
    /**
     * Acessa o Banco de Dados e recolhe as informações sobre os exames cadastrados.
     * Salva as informações na variável "examesJSON".
     */
    return new Promise((resolve, reject) => {
        try {
            getDocs(examsCollection)
                .then((data) => {
                    let examsList = [];
                    data.forEach(exam => {
                        const info = exam.data();
                        info.id = exam.id;
                        examsList.push(info);
                    })
                    resolve(examsList);
                })
                .catch((error) => {reject(error);})
        } catch (error) {
            reject(error);
        }
    })
};

export function getClinics() {
    /**
     * Acessa o Banco de Dados e recolhe as informações sobre consultórios cadastrados.
     * Salva as informações na variável "consultoriosJSON".
     */
    return new Promise((resolve, reject) => {
        try {
            const q = query(clinicsCollection, where('mostrar', '==', true))
            getDocs(q)
                .then((data) => {
                    var clicnicsList = [];
                    data.forEach(clinic => {
                        const info = clinic.data();
                        info.id = clinic.id;
                        clicnicsList.push(info);
                    })
    
                    resolve(clicnicsList);
                })
                .catch(error => {reject(error)});
        }
        catch (error) {
            reject(error);
        }  
    })
};

export async function getClinicImage(imageName) {
  const imageRef = ref(storage, `consultorios/${imageName}.jpg`);

  try {
    const url = await getDownloadURL(imageRef);
    return url;
  } catch (error) {
    console.error(error);

    try {
      const url = await getDownloadURL(ref(storage, `consultorios/foto_indisponivel.png`));
      return url;
    } catch (error) {
      console.error(error);
      throw error; // Re-throw the error to handle it in the calling code if necessary
    }
  }
}