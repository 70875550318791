<template>
  <article class="reviewCard reviewCardSkeleton">
    <div>
      <h3>Carregando...</h3>
      <p>Por favor, aguarde</p>
    </div>
    <div>
      <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis quis alias incidunt, accusamus nemo voluptatum laborum nobis delectus ut autem?</p>
    </div>
  </article>
</template>

<script>
export default {

}
</script>

<style>
.reviewCard.reviewCardSkeleton * {
  font-family: var(--txt-skeleton) !important;
  color: lightgray !important;
  animation: infinite ease-in-out 1500ms;
  animation-name: ReviewCardSkeletonLoading;
}

  @keyframes ReviewCardSkeletonLoading {
    0% {filter: opacity(1);}
    50% {filter: opacity(0.5);}
    100% {filter: opacity(1);}
  }

  .reviewCard.reviewCardSkeleton div:nth-of-type(2) p {margin: 20px auto 10px auto;}
</style>