<template>
  <main>
  <PageSection 
  :Type="`principal`" 
  :Title="`Agende sua consulta`" 
  :Descr="`Fale conosco através do WhatsApp e agende sua consulta facilmente.`">

  <Button @click="openScheduleModal" :BtnTxt="`Entre em contato`"/>
  </PageSection>

  <section>
    <PageSection
    :Type="`secundario`"
    :Title="`Consultórios`"
    :Descr="`Conheça os consultórios do Dr. André em Porto Velho e veja os horários de atendimento.`">
    </PageSection>
    <div id="consultorios">
      <div v-if="clinicsJSON.length > 0">
        <ClinicCard v-for="clinic in clinicsJSON" :key="clinic.id"
        :Name="clinic.nome" :Address="clinic.endereco" :PhoneNumber="clinic.telefone" :Info="clinic.descricao"
        :Photo="clinic.foto"
        :ClinicID="clinic.id"
        :ref="`clinicCard_${clinic.id}`"
        @prevCard="showClinicCard(clinic.id, 'prev')" @nextCard="showClinicCard(clinic.id, 'next')"/>
      </div>
      <div v-if="!(clinicsJSON.length > 0)">
        <ClinicCardSkeleton/>
      </div>
    </div>
  </section>

  <PageSection 
    :Type="`secundario`" 
    :Title="`Sobre o site`" >
    <p>Entre em contato para negócios via <a href="mailto:contato@drandresantospvh.com.br" target="_blank" title="Enviar e-mail comercial para o Dr. André">contato@drandresantospvh.com.br</a>.</p>
    <p>Este site foi desenvolvido por <a href="https://andremourasantos.com" rel="author" target="_blank" hreflang="pt-BR" title="Visitar página de André Moura Santos">André Moura Santos</a>, © 2022-2023. Ícones de <a href="https://phosphoricons.com/" rel="nofollow noreferrer" target="_blank" hreflang="en" title="Visitar Phosphor Icons">Phosphor Icons</a> e ilustrações de <a href="https://www.flaticon.com/authors/pixel-perfect/rounded-shapes?author_id=168" rel="nofollow noreferrer" target="_blank" hreflang="en" title="Visitar Pixel Perfect no Flaticon">Pixel Perfect</a>.</p>
    <p>Leia nossa <span @click="openPrivacyModal" class="txt_click_interaction" title="Abrir Política de Privacidade">Política de Privacidade</span>.</p>
  </PageSection>
  </main>

  <PrivacyPolicy/>
</template>

<script>
import PageSection from '@/components/PageSection.vue'
import Button from '@/components/Button.vue'
import ClinicCard from '@/components/ClinicCard.vue'
import ClinicCardSkeleton from '@/components/ClinicCardSkeleton.vue'
import PrivacyPolicy from '@/components/PrivacyPolicy.vue'
import { globalScheduleStatus } from '@/stores/schedule.js'
import { globalPopupStatus } from '@/stores/popupsStatus.js'

export default {
  components: { PageSection, Button, ClinicCard, PrivacyPolicy, ClinicCardSkeleton },
  data() {
    return {
      globalScheduleStatus,
      globalPopupStatus,
      clinicsJSON: globalScheduleStatus.clinicsJSON,
      clinicsArray: `document.querySelectorAll('#consultorios .clinicCard')`
    }
  },
  methods: {
    openScheduleModal() {
      this.globalScheduleStatus.openModal([])
    },
    showClinicCard(clinicaId, action) {
      const cardsArray = Array.from(eval(this.clinicsArray));
      const card = this.$refs[`clinicCard_${clinicaId}`][0].$el;
      const cardIndex = cardsArray.indexOf(card);
      const isDetailsOpen = card.querySelector('details').open;

      card.style.display = 'none';

      switch(action == 'next'){
        case true:
          if(!(cardIndex == cardsArray.length - 1)){
            cardsArray[cardIndex + 1].style.display = 'grid';
            cardsArray[cardIndex + 1].querySelector('details').open = isDetailsOpen;
          } else {
            cardsArray[0].style.display = 'grid';
            cardsArray[0].querySelector('details').open = isDetailsOpen;
          }
          break;
        case false: 
          if(!(cardIndex == 0)){
            cardsArray[cardIndex - 1].style.display = 'grid';
            cardsArray[cardIndex - 1].querySelector('details').open = isDetailsOpen;
          } else {
            cardsArray[cardsArray.length - 1].style.display = 'grid';
            cardsArray[cardsArray.length - 1].querySelector('details').open = isDetailsOpen;
          }
          break;
      }
    },
    openPrivacyModal() {
      this.globalPopupStatus.showPrivacyPolicy = true;
    }
  },
  watch: {
    'globalScheduleStatus.clinicsJSON'(newValue, oldValue) {
      this.clinicsJSON = newValue;
    }
  }
}
</script>

<style scoped>
main section {
  text-align: left;
}

#consultorios {margin-top: 30px;}

@media screen and (min-width: 768px) {
  /*visão de computador*/
  main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    row-gap: 0px;
    justify-items: center;
  }

  main section {
    max-width: 350px;
  }

  main > section:nth-child(2) {
    grid-row: 2 span;
  }
}
</style>