<template>
<article class="examCard" :aria-label="ExamInfo.nome">
    <img loading="lazy" width="96" height="96" :src="require(`@/assets/icones/${ExamInfo.icone}.png`)">
    <h3>{{ ExamInfo.nome }}</h3>
    <p>{{ ExamInfo.descricao }}</p>
    <details>
        <summary @click="toggleSummary"><span>{{ SummaryTxt }}</span> informações.</summary>
        <Button :BtnType="'primario'" :BtnTxt="'Agendar consulta'" @click="openScheduleModal(ExamInfo)"/>
    </details>
</article>
</template>

<script>
import Button from '@/components/Button.vue'
import { globalScheduleStatus } from '@/stores/schedule.js'

export default {
    props: ['ExamInfo'],
    components: { Button },
    data() {
        return {
            SummaryTxt: 'Mais',
            globalScheduleStatus
        }
    },
    methods: {
        toggleSummary() {
            (this.SummaryTxt == 'Mais') ? this.SummaryTxt = 'Menos' : this.SummaryTxt = 'Mais';
        },
        openScheduleModal(examInfo) {
            this.globalScheduleStatus.openModal(examInfo)
        }
    }
}
</script>

<style>
.examCard {
    background-color: white;
    height: fit-content;
    width: 250px;
    border-radius: var(--borda-raio_grande);
    box-shadow: var(--sombra-leve);
    padding: 30px;
    transition-duration: 500ms;
}

.examCard:has(details[open]) {
    transform: scale(1.05);
    box-shadow: var(--sombra-media);
}

    @media screen and (min-width: 1000px) {
        /*Visão de computador*/
        article.examCard:hover {box-shadow: var(--sombra-media); transform: scale(1.01);}
    }

.examCard > * {display: block; margin-inline: auto;}
.examCard img {margin: 0px auto 20px auto;}
.examCard h3 {font-size: calc(1.25 * var(--txt-tamanho_base));}
.examCard p {text-align: justify;}
.examCard button {margin-top: 20px; width: 100%;}
</style>