<template>
  <dialog v-show="globalScheduleStatus.showModal" id="shedule_modal" class="popup">
    <div>
        <img loading="lazy" src="../assets/icones/localizacao.png" height="128" width="128">
        <h3>Escolha o consultório</h3>
        <p v-if="textVariant == 1">
            <span>{{ examInfo.nome }}</span> está disponível nos seguintes consultórios, escolha dentre as opções abaixo para entrar em contato.
        </p>
        <p v-if="textVariant == 2">
            O Dr. André atende em diversos consultórios, escolha dentre as opções abaixo para entrar em contato.
        </p>
        <section v-if="textVariant == 1">
            <a v-for="clinic in getExamClinicsData" 
                :key="clinic.id"
                target="_blank"
                rel="external"
                :title="`Agendar consulta em ${clinic.nome}`"
                :href="`https://api.whatsapp.com/send/?phone=${clinic.telefone}&text=${encodeURIComponent(`Olá, eu gostaria de agendar uma consulta com o Dr. André oftalmologista para ${examInfo.nome.toLowerCase()}`)}`"
                @click="emitGTMEvent(clinic.id)"
                >
                <Button  
                    :BtnType="'primario'"
                    :BtnTxt="`${clinic.nome} (${clinic.endereco})`"
                    :BtnIcon="'ph-arrow-square-out'"
                />
            </a>
        </section>
        <section v-if="textVariant == 2">
            <a v-for="clinic in globalScheduleStatus.clinicsJSON"
                :key="clinic.id"
                target="_blank"
                rel="external"
                :title="`Agendar consulta em ${clinic.nome}`"
                :href="`https://api.whatsapp.com/send/?phone=${clinic.telefone}&text=${encodeURIComponent(`Olá, eu gostaria de agendar uma consulta com o Dr. André oftalmologista.`)}`"
                @click="emitGTMEvent(clinic.id)"
                >
            <Button  
                :BtnType="'primario'"
                :BtnTxt="`${clinic.nome} (${clinic.endereco})`"
                :BtnIcon="'ph-arrow-square-out'"
            />
            </a>
        </section>
        <Button :BtnType="'secundario'" :BtnTxt="'Fechar'" @click="closeModal"/>
    </div>
</dialog>
</template>

<script>
import Button from '@/components/Button.vue'
import { useGtm } from "@gtm-support/vue-gtm";
import { globalScheduleStatus } from '@/stores/schedule.js'

export default {
    props: ['ClinicsJSON'],
    components: { Button },
    data() {
        return {
            globalScheduleStatus,
            textVariant: 1,
            examInfo : [],
            modalEl: `document.querySelector('#shedule_modal')`
        }
    },
    methods: {
        openModal() {
            const modal = eval(this.modalEl)
            const html = document.querySelector('html')

            Object.values(this.globalScheduleStatus.examInfo).length > 0 ? this.textVariant = 1 : this.textVariant = 2

            modal.style.animationName = 'openModal'
            html.style.overflowY = 'hidden'
            modal.showModal()

            setTimeout(() => {
                modal.scrollTo(0,0)
            }, 10);
        },
        emitGTMEvent(consultorioID) {
          if(globalScheduleStatus.examInfo.length !== 0){
            useGtm().trackEvent({
              event: 'agendar-consulta',
              exameId: globalScheduleStatus.examInfo.id,
              consultorioId: consultorioID
            })
          } else {
            useGtm().trackEvent({
              event: 'agendar-consulta',
              exameId: undefined,
              consultorioId: consultorioID
            })
          }
        },
        closeModal() {
            const modal = eval(this.modalEl)
            const html = document.querySelector('html')
            modal.style.animationName = 'closeModal'

            setTimeout(()=>{
                modal.close()
                html.style.overflowY = 'scroll'

                this.globalScheduleStatus.showModal = false
            }, 250)
        }
    },
    computed: {
        getExamClinicsData() {
            const allClinics = this.globalScheduleStatus.clinicsJSON
            const examInfo = this.examInfo

            if(examInfo.consultorios){
                return allClinics.filter(clinic => {
                    if(examInfo.consultorios.includes(clinic.id))
                    {return true} else{return false}
                })
            }
        }
    },
    watch: {
        'globalScheduleStatus.showModal'(newValue, oldValue) {if(newValue === true){
            this.examInfo = this.globalScheduleStatus.examInfo
            this.openModal()
        }}
    }
}
</script>

<style>
#shedule_modal section:has(a > button) {
    display: grid;
    row-gap: 15px;
    margin: 15px auto;
}

#shedule_modal section:has(a > button) a {
    width: 100%;
}
</style>