<template>
  <dialog id="privacy_popup" class="popup" v-show="showPopup">
    <img loading="lazy" src="@/assets/icones/politica_de_privacidade.png" height="128" width="128">
    <h3>Política de Privacidade</h3>
    <p>A política de privacidade é um documento que descreve como uma empresa ou organização coleta, usa e protege as informações pessoais de seus usuários ou clientes. Ela também explica os motivos pelos quais essas informações são coletadas e como são usadas, bem como se são compartilhadas com terceiros.</p>
    <p>Nesta política de privacidade, serão abordados os temas abaixo:</p>
    <ul>
        <li>Tipos de informações pessoais coletadas</li>
        <li>Finalidade da coleta de informações</li>
        <li>Compartilhamento de informações</li>
        <li>Segurança de informações</li>
        <li>Formas de contato</li>
    </ul>
    <p>É importante ler e entender a política de privacidade de qualquer site ou serviço antes de fornecer suas informações pessoais.</p>

    <h4>Tipos de informações pessoais coletadas</h4>
    <p>Este site utiliza serviços que coletam informações pessoais como endereço de IP, localização geográfica, tipo de navegador e dispositivo, páginas visitadas, duração da visita e dados demográficos. Essas informações são coletadas para ajudar os proprietários do site a entender como os usuários interagem com a página sendo utilizadas para personalização da experiência com funcionalidades dentro do site, anúncios e mensagens de marketing. Você pode desativar o rastreamento de cookies de publicidade e o compartilhamento de dados com terceiros para fins publicitários em <a href="https://myadcenter.google.com/customize" hreflang="pr-BR" target="_blank" rel="nofollow" title="Visitar Google Ad Center">Google Ad Center</a>.</p>

    <h4>Finalidade da coleta de informações</h4>
    <p>A finalidade da coleta de informações pessoais pelo Google Analytics e Google Ads é aprimorar a experiência do usuário no site e auxiliar com campanhas de marketing. As informações coletadas, como endereço de IP, localização geográfica, tipo de navegador e dispositivo, páginas visitadas e duração da visita, são usadas para entender como os usuários interagem com o site e para fornecer anúncios e mensagens de marketing relevantes. Além disso, essas informações podem ser usadas para identificar padrões de uso e para aprimorar o conteúdo e a funcionalidade do site. Em resumo, a finalidade da coleta de informações é oferecer uma experiência mais personalizada e relevante aos usuários do site.</p>
    <h4>Compartilhamento de informações</h4>

    <p>O compartilhamento de informações pessoais coletadas pelo Google Analytics e Google Ads pode variar ligeiramente para cada usuário, mas por padrão, o Google compartilha algumas dessas informações com terceiros, como anunciantes e parceiros, para fornecer anúncios e mensagens de marketing personalizados. No entanto, os usuários têm a opção de desativar o rastreamento de cookies de publicidade e o compartilhamento de dados com terceiros para fins publicitários através das configurações de privacidade do navegador.</p>
    <h4>Segurança de informações</h4>

    <p>O site utiliza os serviços do Google, como o Google Analytics e o Google Ads, que implementa medidas de segurança para acesso às informações como criptografia de dados, autenticação de usuário e proteção contra ataques de hackers. Além disso, o Google utiliza firewalls e outras tecnologias de segurança para proteger os seus servidores e sistemas de acesso não autorizado. O acesso às informações coletadas pelas ferramentas utilizadas no site é restrito apenas à pessoas confiáveis e que participaram do processo de desenvolvimento do site.</p>

    <h4>Formas de contato</h4>
    <p>Você pode entrar em contato para obter mais informações enviando um e-mail para <a href="mailto:contato@drandresantospvh.com.br" target="_blank" title="Enviar e-mail comercial para o Dr. André" style="word-wrap: break-word;">contato@drandresantospvh.com.br</a>. Buscaremos atender o seu pedido o quanto antes.</p>
    <p>Ao continuar navegando no site, você concorda com nossa política de privacidade em sua totalidade.</p>

    <div class="popup_container_botoes">
      <Button :BtnType="'primario'" :BtnTxt="'Continuar'" @click="close"/>
    </div>
  </dialog>
</template>

<script>
import { globalPopupStatus } from '@/stores/popupsStatus.js'
import { openModal, closeModal } from '@/composables/popupsActions.js'
import Button from '@/components/Button.vue'

export default {
  components: {Button},
  data() {
    return {
      globalPopupStatus,
      showPopup: false,
      modalEl: `document.querySelector('#privacy_popup')`
    }
  },
  methods: {
    open() {
      this.showPopup = true;
      openModal(this.modalEl);
    },
    close() {
      closeModal(this.modalEl);
      
      setTimeout(() => {
        this.globalPopupStatus.showPrivacyPolicy = false;
      }, 250)
    }
  },
  watch: {
    'globalPopupStatus.showPrivacyPolicy'(newValue, oldValue) {
      newValue == true ? this.open() : this.showPopup = false;
    }
  }
}
</script>

<style>

</style>