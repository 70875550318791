import { reactive } from 'vue'

export const globalScheduleStatus = reactive({
  showModal: false,
  examInfo: [],
  openModal(examInfo) {
    this.showModal = true
    this.examInfo = examInfo
  },
  examsJSON: [],
  clinicsJSON: []
})