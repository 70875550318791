<template>
<main>
  <section>
    <PageSection
    :Type="`principal`"
    :Title="`Consultas oftalmológicas ágeis e de excelência.`"
    :Descr="`Consulte-se com o Dr. André, 18 anos de experiência atendendo a todas as idades na capital a preços populares.`">
    
      <Button :BtnType="'primario'" :BtnTxt="'Conheça mais'" @click="scrollToExams"/>
    </PageSection>
    
    <picture>
      <source srcset="../assets/dr_andre_pose1.webp" type="image/webp">
      <img src="../assets/dr_andre_pose1.png" alt="Foto do Dr. André da Silva Santos, oftalmologista em Porto Velho." height="345" width="260">
    </picture>
  </section>

  <section>
    <PageSection :Type="'secundario'" :Title="'Exames populares'" :Descr="'O Dr. André oferece diversos exames oftalmológicos em Porto Velho, confira os mais populares abaixo:'"/>

    <div id="exames_populares">
      <div class="carousel_gradient carousel_gradient_top"></div>
        <div v-if="examsJSON.length > 0" class="carousel_container">
          <ExamCard v-for="exam in featuredExams" :key="exam.id" :ExamInfo="exam"/>
        </div>
        <div v-if="!examsJSON.length > 0" class="carousel_container">
          <ExamCardSkeleton v-for="index in 3" :key="index"/>
        </div>
      <div class="carousel_gradient carousel_gradient_bottom"></div>
    </div>

    <router-link to="exames">
      <Button :BtnType="'secundario'" :BtnTxt="'Ver exames'" :BtnLink="'exames'" />
    </router-link>
  </section>

  <section>
    <PageSection 
    :Type="'secundario'" 
    :Title="'Avaliação dos pacientes'" 
    :Descr="'Veja o que dizem os pacientes atendidos pelo Dr. André.'"/>

    <div id="avaliacoes">
      <div class="carousel_gradient carousel_gradient_top"></div>
        <div v-if="reviewsJSON[0]" class="carousel_container">
          <ReviewCard v-for="review in reviewsJSON[0]"
          :key="review" :Name="review.name" :ReviewDate="review.date" :ReviewComment="review.reviewComment" :ReviewSourceURL="review.sourceURL" :ReviewSourceName="review.source" />
        </div>
        <div v-if="!reviewsJSON[0]" class="carousel_container">
          <ReviewCardSkeleton v-for="index in 5" :key="index"/>
        </div>
      <div class="carousel_gradient carousel_gradient_bottom"></div>
    </div>

    <router-link to="contato">
      <Button :BtnType="'secundario'" :BtnTxt="'Entrar em contato'" />
    </router-link>
  </section>

  <section>
    <PageSection 
    :Type="'secundario'" 
    :Title="'Sobre o Dr. André'" 
    :Descr="'Atendendo há mais de 18 anos em clínicas populares em Porto Velho, o Dr. André realiza diversas consultas oftalmológicas para todas as idades, sendo especialista em oftalmopediatria e estrabismo.'"/>
  </section>
</main>
</template>

<script>
import PageSection from '@/components/PageSection.vue'
import Button from '@/components/Button.vue'
import ExamCard from '@/components/ExamCard.vue'
import ExamCardSkeleton from '@/components/ExamCardSkeleton.vue'
import ReviewCard from '@/components/ReviewCard.vue'
import ReviewCardSkeleton from '@/components/ReviewCardSkeleton.vue'

import { getReviews } from '@/composables/basic.js'
import { globalScheduleStatus } from '@/stores/schedule.js'
import reviews from '@/stores/reviews.js'

export default {
  name: 'PaginaInicial',
  components: { PageSection, Button, ExamCard, ExamCardSkeleton, ReviewCard, ReviewCardSkeleton },
  data() {
    return {
      globalScheduleStatus,
      reviews,
      examsJSON: globalScheduleStatus.examsJSON,
      reviewsJSON: reviews.reviewsList
    }
  },
  computed: {
    featuredExams() {
      return this.examsJSON.filter(exam => {if(exam.destaque == true){return true} else{return false}})
    }
  },
  methods: {
    scrollToExams(){
      document.querySelector("#app > main > section:nth-child(2)").scrollIntoView()
    }
  },
  watch: {
    'globalScheduleStatus.examsJSON'(newValue, oldValue) {
      this.examsJSON = newValue;
    },
    'reviews.reviewsList'(newValue, oldValue) {
      this.reviewsJSON = newValue;
    }
  },
  mounted() {
    getReviews('https://www.doctoralia.com.br/andre-da-silva-santos/oftalmologista/porto-velho')
      .then(reviewList => {reviews.reviewsList.push(reviewList)})
      .catch(error => {console.log(error)})
  }
}
</script>

<style scoped>
  main > section:nth-of-type(1) > picture > img {
    display: block;
    height: fit-content;
    width: 80%;
    margin-inline: auto;
    margin-top: 60px;
  }

  @media screen and (min-width: 425px) {
    /*visualização de tablet*/
    main > section:nth-of-type(1) {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      justify-content: space-around;
    }

    main > section:nth-of-type(1) > section:nth-of-type(1) {
      text-align: left;
      max-width: 90%;
    }

    main > section:nth-of-type(1) > picture > img {
      width: 250px;
      margin: unset;
    }
  }

  @media screen and (min-width: 1000px) {
    main > section:nth-of-type(1) {
      grid-template-columns: 500px auto;
    }

    main > section:nth-of-type(1) > section:nth-of-type(1) {
      text-align: left;
      max-width: unset;
    }
  }
  
  main > section > a {
    margin-inline: auto;
  }

  #exames_populares, #avaliacoes {
    position: relative;
    display: grid;
    height: 500px;
    margin-top: -15px;
    overflow: auto;
  }

  #avaliacoes .carousel_container {margin: 0px;}

  .carousel_container {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    justify-content: center;
    align-content: flex-start;
    padding: 60px 0px;
    margin: -60px auto;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .carousel_container::-webkit-scrollbar {
    display: none;
  }

  .examCard, .reviewCard {
    z-index: 1;
  }

  .carousel_gradient {
    position: sticky;
    width: 100%;
    height: 60px;
    margin-bottom: -100%;
    top: 0px;
    background: linear-gradient(180deg, var(--cor-plano_de_fundo_pagina), transparent);
    z-index: 2;
  }

  .carousel_gradient_top {
    top: 0px;
  }

  .carousel_gradient_bottom {
    background: linear-gradient(0deg, var(--cor-plano_de_fundo_pagina), transparent);
    bottom: 0px;
    margin-top: -60px;
  }

  /*↓ Responvidade do carrossel*/
  @media screen and (min-width: 768px){
    /*visualização de computador*/

    #exames_populares, #avaliacoes {
      height: fit-content;
    }

    .carousel_container {
      flex-wrap: nowrap;
      padding: 60px;
      height: fit-content;
      width: fit-content;
      margin: 0px;
    }

    .carousel_gradient {
      display: none;
    }
  }
</style>