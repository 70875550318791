<template>
  <Header />
  <router-view />
  <ScheduleModal v-if="globalScheduleStatus.examsJSON.length > 0" />
</template>

<script>
//↓ Components
import Header from '@/components/Header.vue';
import ScheduleModal from '@/components/ScheduleModal.vue';

//↓ Composables
import { getExams, getClinics } from '@/composables/db.js';
// import { createClinicsCollection, createExamsCollection } from '@/composables/emulators.js';

//↓ Stores
import { globalScheduleStatus } from '@/stores/schedule.js';

//↓ Firebase global config
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";

export default {
  components: { Header, ScheduleModal },
  data() {
    return {
      globalScheduleStatus
    }
  },
  methods: {
    checkIfEmulatorWasUsed() {
      if(sessionStorage.getItem('emulatorUsed') !== 'true'){return false} else {return true}
    }
  },
  mounted() {
    const firebaseConfig = {
      apiKey: "AIzaSyASj5p6uV4CjsaAvq2Nb2OaMiqq8cRTfJ8",
      authDomain: "dr-andre-pvh.firebaseapp.com",
      projectId: "dr-andre-pvh",
      storageBucket: "dr-andre-pvh.appspot.com",
      messagingSenderId: "996943105135",
      appId: "1:996943105135:web:615ead3efa21544302919a",
      measurementId: "G-NXVMHNC09L"
    };

    const app = initializeApp(firebaseConfig);
    const appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider('6LduXE8kAAAAAIYzwweypY9hjIuk9wvI6qXGt_jl'),
      isTokenAutoRefreshEnabled: true
    });
    const analytics = getAnalytics(app);
    //↑ CONFIGURAÇÕES DO FIREBASE

    //↓ USO EM AMBIENTE DE TESTE
    if(window.location.host === 'localhost:5500'){
      if(!this.checkIfEmulatorWasUsed()){
        Promise.all([createExamsCollection(), createClinicsCollection()])
          .then(async ([exams, clinics]) => {
            sessionStorage.setItem('emulatorUsed', 'true');
          })
          .catch(error => {console.log(error)})
      }
    }

    //↓ USO EM AMBIENTE DE PRODUÇÃO
    Promise.all([getExams(), getClinics()])
      .then(([exams, clinics]) => {
        this.globalScheduleStatus.examsJSON = exams;
        this.globalScheduleStatus.clinicsJSON = clinics;
      })
      .catch(error => {console.log(error)})
  }
}
</script>

<style>
@charset "UTF-8";

@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700&family=Flow+Circular&display=swap');

@import url('https://unpkg.com/@phosphor-icons/web@2.0.3/src/regular/style.css');

/*↓ RAÍZ*/
:root {
  /*FONTES*/
  --txt-h: 'Atkinson Hyperlegible', sans-serif;
  --txt-p: 'Atkinson Hyperlegible', sans-serif;
  --txt-skeleton: 'Flow Circular', cursive;

  /*TEXTO*/
  --txt-tamanho_base: 16px;
  --txt-cor_h: #1F1F1F;
  --txt-cor_p: #333333;

  /*CORES*/
  --cor-plano_de_fundo_pagina: #FAFAFA;
  --cor-plano_de_fundo_menu_navegacao: #FFFFFF;
  --cor-plano_de_fundo_popups: #FFFFFF;
  --cor-destaque_3: #F7ECDE;

  --cor-btn-primario: #00B871;
  --cor-btn-secundario: #54BAB9;
  --cor-btn-popup_primario: #00B871;
  --cor-btn-popup_secundario: #7F7F7F;
  /*↑↑ Seis tons mais claro que --cor_txt_paragrafo*/

  --cor-btn-primario_txt: #FFFFFF;
  --cor-btn-secundario_txt: #FFFFFF;
  --cor-btn-primario_popup_txt: #FFFFFF;
  --cor-btn-secundario_popup_txt: #FFFFFF;

  /*BORDAS*/
  --borda-raio_grande: 30px;
  --borda-raio_medio: 15px;
  --borda-raio_pequeno: 5px;

  /*SOMBRAS*/
  --sombra-header: 0px 0px 100px #00000020;
  --sombra-leve: 0px 0px 15px #00000010;
  --sombra-media: 0px 0px 30px #00000020;
  --sombra-botao: 3px 3px 5px #00000020;
}

/*↓ GERAL*/
html {scroll-behavior: smooth;}
* {margin: 0; padding: 0; font-family: var(--txt-p); line-height: 1.5; color: var(--txt-cor_p); box-sizing: border-box; font-size: var(--txt-tamanho_base);}
body {background-color: var(--cor-plano_de_fundo_pagina); margin: 60px 30px 160px 30px;}
h1,h2,h3,h4,h5,h6 {font-weight: bold; font-family: var(--txt-h); color: var(--txt-cor_h); line-height: initial;}
h1 {font-size: calc(1.85 * var(--txt-tamanho_base));}
h2 {font-size: calc(1.625 * var(--txt-tamanho_base));}
h3 {font-size: calc(1.50 * var(--txt-tamanho_base));}
h4, h5, h6 {font-size: calc(1.25 * var(--txt-tamanho_base));}

p, a {display: block; width: fit-content;}
.txt_click_interaction {text-decoration: underline; cursor: pointer;}
a {text-decoration: underline; color: var(--txt-cor_p);}
a:has(button) {text-decoration: none;}
ul, ol {list-style-position: inside}

  /*↓ Responsividade*/
  @media screen and (min-width: 425px){
    /*Visão de tablet*/
    body {margin: 60px 60px 200px 60px;}
    h1 {font-size: calc(2.5 * var(--txt-tamanho_base));}
    h2 {font-size: calc(2 * var(--txt-tamanho_base));}
    h3 {font-size: calc(1.75 * var(--txt-tamanho_base));}
    h4, h5, h6 {font-size: calc(1.50 * var(--txt-tamanho_base));}
  }

  @media screen and (min-width: 1000px){
    /*Visão de computador*/
    body {max-width: 1440px; margin: 0 auto;}
    h1 {font-size: calc(1.625 * var(--txt-tamanho_base));}
    h2 {font-size: calc(2 * var(--txt-tamanho_base));}
    h3 {font-size: calc(1.35 * var(--txt-tamanho_base));}
    h4, h5, h6 {font-size: calc(1.50 * var(--txt-tamanho_base));}
  }

/*↓ MAIN*/
main {
  display: grid;
  row-gap: 60px;
}

  /*↓ Responsividade do main*/
  @media screen and (min-width: 1000px) {
    main {margin: 0px 5vw 120px 5vw;}
  }

main section p {margin: 10px 0px;}
main section a:not(:has(button)), main section p {display: inline-block;}

/*↓ DIALOGS*/
dialog.popup {
  position: fixed;
  max-height: 80%;
  max-width: 80vw;
  margin: auto;
  padding: 30px;
  background-color: var(--cor-plano_de_fundo_popups);
  border-radius: var(--borda-raio_grande);
  border: none;
  box-sizing: border-box;
  box-shadow: var(--sombra-media);   
  animation: ease-in-out 250ms;
  animation-name: openModal;
}

  @keyframes openModal {
    0% {transform: scale(0.9); opacity: 0;}
    100% {transform: scale(1); opacity: 1;}
  }

  @keyframes closeModal {
    0% {transform: scale(1); opacity: 1;}
    100% {transform: scale(0.9); opacity: 0;}
  }

  @media screen and (min-width: 425px) {
    /*Visualização de tablet*/
    dialog.popup {
      max-width: 400px;
      padding: 40px;
    }
  }

dialog.popup img {display: block; margin: auto auto 30px auto;}
dialog.popup h4 {margin: 40px auto -10px auto;}
dialog.popup p:first-of-type {margin: 5px auto;}
dialog.popup p {text-align: justify; margin: 20px auto;}
dialog a {display: inline-block;}
dialog.popup button {width: 100%;}

/*↓ SUMMARY*/
details > summary {list-style: none; text-decoration: underline; cursor: pointer;}
</style>