<template>
  <main>
    <PageSection :Type="'principal'" :Title="'Página não encontrada'" :Descr="'Parece que a página que você tentou acessar não existe, confira o link ou clique no botão abaixo para voltar à página inicial.'">
      <router-link :to="{name: 'inicio'}">
        <Button :BtnTxt="'Voltar à página inicial'" />
      </router-link>
    </PageSection>
  </main>
</template>

<script>
import PageSection from '@/components/PageSection.vue'
import Button from '@/components/Button.vue'

export default {
  components: { PageSection, Button }
}
</script>

<style scoped>
  @media screen and (min-width: 425px) {
    main {
      max-width: 600px;
      margin: auto;
    }

    section a:has(button) {
      margin: auto;
    }
  }
</style>