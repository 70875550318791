<template>
<button
:class="{
'btn-principal': (BtnType !== undefined && BtnType.includes('primario')),
'btn-secundario': (BtnType !== undefined && BtnType.includes('secundario')),
'btn-popup': (BtnType !== undefined && BtnType.includes('popup')),
}">
    <span>{{ BtnTxt }}</span>
    <i v-if="BtnIcon" class="ph" :class="BtnIcon"></i>
</button>
</template>

<script>
export default {
    props: ['BtnType', 'BtnTxt', 'BtnIcon']
}
</script>

<style>
button, a button {
    padding: 10px 20px;
    background-color: var(--cor-btn-primario);
    border: none;
    border-radius: var(--borda-raio_grande);
    cursor: pointer;
    font-family: var(--txt-titulo);
    font-weight: 600;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
    box-shadow: var(--sombra-botao);
}

button * {color: var(--cor-btn-primario_txt);}

button.btn-secundario {
    background-color: var(--cor-btn-secundario);
    color: var(--cor-btn-secundario_txt);
    border-radius: var(--borda-raio_medio);
}

button.btn-secundario * {color: var(--cor-btn-secundario_txt);}

button:has(i) {
    display: inline-flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

button i {
    font-size: var(--txt-tamanho_base);
    color: white;
}
</style>