<template>
  <router-link :to="{name: VueRoute}" :title="TitleAcc" :aria-label="TitleAcc">
        <img :src="require(`@/assets/${ImgName}.png`)" :alt="AltAcc" width="32" height="32">
        <p>{{ PageName }}</p>
    </router-link>
</template>

<script>
export default {
    props: ['VueRoute', 'TitleAcc', 'ImgName', 'AltAcc', 'PageName']
}
</script>

<style>
    header nav a {
        display: grid;
        justify-items: center;
        row-gap: 2px;
        height: 50px;
        width: 50px;
        filter: grayscale(1);
    }

    header nav a p {font-size: calc(0.75 * var(--txt-tamanho_base));}

    header nav a.router-link-active {
        filter: grayscale(0);
        font-weight: bold;
    }

    /*↓ Responsividade do cabeçalho*/
    @media screen and (min-width: 425px) {
        /*Visão de tablet*/
        header nav img {
            height: 36px;
            width: 36px;
        }

        header nav a {
            grid-template-columns: 36px auto;
            column-gap: 10px;
            align-items: center;
            height: 36px;
            width: 100px;
            justify-content: flex-start;
        }

        header nav a p {
            font-size: calc(0.9 * var(--txt-tamanho_base));
        }
    }

    @media screen and (min-width: 1000px) {
        /*Visão de computador*/
         
    }
</style>