<template>
  <article class="clinicCard clinicCardSkeleton">
    <section>
      <div></div>
      <div>
        <h3>Carregando</h3>
        <p>Por favor, aguarde...</p>
      </div>
      <div></div>
    </section>
  </article>
</template>

<script>
export default {

}
</script>

<style>
.clinicCardSkeleton {
  background-color: lightgrey !important;
  user-select: none;
}

.clinicCardSkeleton h3, .clinicCardSkeleton p {
  font-family: var(--txt-skeleton);
  color: darkgray !important;
  animation: infinite ease-in-out 1500ms;
  animation-name: ClinicCardSkeletonLoading;
}

@keyframes ClinicCardSkeletonLoading {
  0% {filter: opacity(1);}
  50% {filter: opacity(0.5);}
  100% {filter: opacity(1);}
}
</style>