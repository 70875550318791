<template>
  <header>
    <section>
        <h1>Dr. André da Silva Santos</h1>
        <p>Oftalmologista em Porto Velho.</p>
    </section>
    <section>
        <nav :style="`grid-template-columns: repeat(${linksInfo.length}, auto);`">
            <HeaderLink v-for="link in linksInfo"
            :key="link.vueRoute"
            :VueRoute="link.vueRoute"
            :TitleAcc="link.titleAcc"
            :ImgName="link.imgName"
            :AltAcc="link.altAcc"
            :PageName="link.pageName"/>
        </nav>
    </section>
  </header>
</template>

<script>
import HeaderLink from './HeaderLink.vue';

export default {
    components: { HeaderLink },
    data() {
        return {
            linksInfo: [
            {
                'vueRoute': 'inicio',
                'titleAcc': 'Navegar para início',
                'imgName': 'pag_inicial',
                'altAcc': '',
                'pageName': 'Início'
            },
            {
                'vueRoute': 'exames',
                'titleAcc': 'Navegar para exames',
                'imgName': 'pag_servicos',
                'altAcc': '',
                'pageName': 'Exames'
            },
            {
                'vueRoute': 'contato',
                'titleAcc': 'Navegar para contato',
                'imgName': 'pag_contato',
                'altAcc': '',
                'pageName': 'Contato'
            }
        ]
        }
    }
}
</script>

<style>
header {
    display: grid;
    height: 80px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0px;
    box-shadow: var(--sombra-header);
    border-radius: var(--borda-raio_grande) var(--borda-raio_grande) 0 0;
    background-color: var(--cor-plano_de_fundo_menu_navegacao);
    z-index: 999;
}

header section {display: grid; align-items: center;}
header section:first-of-type {display: none;}

header nav {
    display: grid;
    /* grid-template-columns: repeat(3, auto); */
    justify-items: center;
    justify-content: space-around;
}

header nav a {text-decoration: none;}

    /*↓ Responsividade do cabeçalho*/
    @media screen and (min-width: 425px) {
        header nav {
            justify-content: space-evenly;
        }
    }

    @media screen and (min-width: 1000px) {
        /*Visão de computador*/
        header {
            height: 100px;
            position: relative;
            grid-template-columns: 0.5fr 0.5fr;
            margin-bottom: 50px;
            border-radius: 0 0 var(--borda-raio_grande) var(--borda-raio_grande);
            padding: 0 60px;
        }

        header section:first-of-type {
            display: grid;
            justify-content: start;
            align-content: center;
        }

        header nav {
            justify-content: end;
            column-gap: 3vw;
        }
    }
</style>