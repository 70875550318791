export function openModal(modalEl) {
    const html = document.querySelector('html');
    const modal = eval(modalEl);

    modal.style.animationName = 'openModal'
    html.style.overflowY = 'hidden'
    modal.showModal()

    setTimeout(() => {
        modal.scrollTo(0,0)
    }, 10);
};
  
export function closeModal(modalEl) {
    const html = document.querySelector('html')
    const modal = eval(modalEl);

    modal.style.animationName = 'closeModal'

    setTimeout(()=>{
        modal.close()
        html.style.overflowY = 'scroll'
    }, 250)
};